import React, { useState } from 'react';
import defaultImage from '../../assets/upload.png';

const Documents = ({ formData, setFormData, errors, handleChange }) => {

    const handleFileChange = (e, name) => {
        const file = e.target.files[0];
        if (file) {
            setFormData((prevData) => ({
                ...prevData,
                [name]: file, // Store the file object directly
            }));
        }
    };

    const renderImage = (file, cardName) => {
        const src = file ? URL.createObjectURL(file) : defaultImage;
        return (
            <img
                src={src} // Use a local object URL
                alt={cardName}
                className={`${file ? 'w-full h-full object-full' : 'w-[100px] h-[100px] object-contain'} rounded-lg`}
            />
        );
    };

    return (
        <div className='px-0 md:px-[15%] py-10 space-y-10 text-right'>
            <h1 className="text-2xl font-bold">المستندات</h1>
            <div className="flex flex-col text-center px-3 md:px-[0%] space-y-10 ">
                <div className='space-y-5'>
                    <h5 className="text-xl font-bold">بطاقة الرقم القومى</h5>
                    <div className='flex flex-col lg:flex-row justify-between space-y-5 lg:space-y-0'>
                        {/* Card 1 */}
                        <div className='w-full lg:w-[45%] space-y-5'>
                            <span className="text-xl font-md">وجه البطاقة الأمامى <span className="text-red-500">*</span></span>
                            <label className="bg-white shadow-lg rounded-lg w-full h-[300px] border border-gray-300 flex flex-col justify-center items-center object-contain cursor-pointer">
                                {renderImage(formData.file_f_national_id, 'Front National ID')}
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) => handleFileChange(event, 'file_f_national_id')}
                                    className="hidden"
                                />
                            </label>
                            {errors.file_f_national_id && <label className="text-red-500 text-sm mt-1" htmlFor="file_f_national_id">{errors.file_f_national_id}</label>}

                        </div>


                        {/* Card 2 */}
                        <div className='w-full lg:w-[45%] space-y-5'>
                            <span className="text-xl font-md">وجه البطاقة الخلفى <span className="text-red-500">*</span></span>
                            <label className="bg-white shadow-lg rounded-lg w-full h-[300px] border border-gray-300 flex flex-col justify-center items-center object-contain cursor-pointer">
                                {renderImage(formData.file_r_national_id, 'Rear National ID')}
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) => handleFileChange(event, 'file_r_national_id')}
                                    className="hidden"
                                />
                            </label>
                            {errors.file_r_national_id && <label className="text-red-500 text-sm mt-1" htmlFor="file_r_national_id">{errors.file_r_national_id}</label>}
                        </div>
                    </div>
                </div>

                <div className='space-y-5'>
                    <h5 className="text-xl font-bold">رخصة المركبة</h5>
                    <div className='flex flex-col lg:flex-row justify-between space-y-5 lg:space-y-0'>
                        {/* Card 1 */}
                        <div className='w-full lg:w-[45%] space-y-5'>
                            <span className="text-xl font-md">وجه الرخصة الأمامى <span className="text-red-500">*</span></span>
                            <label className="bg-white shadow-lg rounded-lg w-full h-[300px] border border-gray-300 flex flex-col justify-center items-center object-contain cursor-pointer">
                                {renderImage(formData.file_f_car_license, 'Front Car License')}
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) => handleFileChange(event, 'file_f_car_license')}
                                    className="hidden"
                                />
                            </label>
                            {errors.file_f_car_license && <label className="text-red-500 text-sm mt-1" htmlFor="file_f_car_license">{errors.file_f_car_license}</label>}
                        </div>


                        {/* Card 2 */}
                        <div className='w-full lg:w-[45%] space-y-5'>
                            <span className="text-xl font-md">وجه الرخصة الخلفى <span className="text-red-500">*</span></span>
                            <label className="bg-white shadow-lg rounded-lg w-full h-[300px] border border-gray-300 flex flex-col justify-center items-center object-contain cursor-pointer">
                                {renderImage(formData.file_r_car_license, 'Rear Car License')}
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) => handleFileChange(event, 'file_r_car_license')}
                                    className="hidden"
                                />
                            </label>
                            {errors.file_r_car_license && <label className="text-red-500 text-sm mt-1" htmlFor="file_r_car_license">{errors.file_r_car_license}</label>}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Documents;
