import React, { useEffect, useState } from 'react';
import { usePreferences } from '../../Context/PreferencesContext';

const Client = ({ formData, handleChange, errors }) => {
    const { getJobs, isJobs } = usePreferences();
    const { getGovernorates, isGovernorates } = usePreferences();

    const [isAddressCollapsed, setIsAddressCollapsed] = useState(false); // To toggle address collapse

    useEffect(() => {
        getJobs();
        getGovernorates();
    }, []);

    // Function to handle phone number input changes
    const handlePhoneChange = (e, field) => {
        const input = e.target.value;
        // Allow only digits and ensure it starts with +20
        const filteredInput = input.replace(/[^\d]/g, '');

        // Format to +20xxxxxxx
        if (filteredInput.startsWith('20')) {
            handleChange({ target: { name: field, value: `+${filteredInput}` } });
        } else if (filteredInput.length === 0) {
            handleChange({ target: { name: field, value: '' } });
        }
    };

    return (
        <div className='px-3 md:px-[15%] py-5 space-y-10'>
            <h1 className="text-2xl font-bold">بيانات العميل</h1>

            <div className='flex flex-col lg:flex-row justify-between space-y-5 lg:space-y-0'>
                <div className="w-full lg:w-[45%]">
                    <label className="block text-sm font-medium pb-3" htmlFor="fullname">
                        الأسم رباعي  <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="fullname"
                        id="fullname"
                        value={formData.fullname}
                        onChange={handleChange}
                        className={`border ${errors.fullname ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12 `}
                    />
                    {errors.fullname && <label className="text-red-500 text-sm mt-1" htmlFor="fullname">{errors.fullname}</label>}
                </div>


                {/* Email */}
                <div className="w-full lg:w-[45%]">
                    <label className="block text-sm font-medium pb-3" htmlFor="email">البريد الإلكتروني</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`border ${errors.email ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12 `}
                    />
                    {errors.email && <label className="text-red-500 text-sm mt-1" htmlFor="email">{errors.email}</label>}
                </div>
            </div>

            {/* National ID and End of National ID */}
            <div className='flex flex-col lg:flex-row justify-between space-y-5 lg:space-y-0'>
                <div className="w-full lg:w-[45%]">
                    <label className="block text-sm font-medium pb-3" htmlFor="national_id"
                    >الرقم القومي <span className="text-red-500">*</span></label>
                    <input
                        type="text"
                        name="national_id"
                        id="national_id"
                        value={formData.national_id}
                        onChange={handleChange}
                        className={`border ${errors.national_id ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12 `}
                    />
                    {errors.national_id && <label className="text-red-500 text-sm mt-1" htmlFor="national_id">{errors.national_id}</label>}
                </div>

                <div className="w-full lg:w-[45%]">
                    <label className="block text-sm font-medium pb-3" htmlFor="national_id_end"
                    >تاريخ انتهاء الرقم القومي <span className="text-red-500">*</span></label>
                    <input
                        type="date"
                        name="national_id_end"
                        id="national_id_end"
                        value={formData.national_id_end}
                        onChange={handleChange}
                        className={`border ${errors.national_id_end ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12 `}
                    />
                    {errors.national_id_end && <label className="text-red-500 text-sm mt-1" htmlFor="national_id_end">{errors.national_id_end}</label>}
                </div>
            </div>

            {/* Phone and Another Phone */}
            <div className='flex flex-col lg:flex-row justify-between space-y-5 lg:space-y-0'>
                <div className="w-full lg:w-[45%]">
                    <label className="block text-sm font-medium pb-3" htmlFor="phone">
                        رقم الموبايل <span className="text-red-500">*</span></label>
                    <input
                        type="tel"
                        name="phone"
                        id="phone"
                        value={formData.phone}
                        onChange={(e) => handlePhoneChange(e, 'phone')}
                        pattern="^\+20[0-9]{10}$" // Ensures the phone starts with +20 and has 10 digits
                        className={`border ${errors.phone ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12 `}
                    />
                    {errors.phone && <label className="text-red-500 text-sm mt-1" htmlFor="phone">{errors.phone}</label>}
                </div>

                <div className="w-full lg:w-[45%]">
                    <label className="block text-sm font-medium pb-3" htmlFor="another_phone">رقم موبايل اضافى</label>
                    <input
                        type="tel"
                        name="another_phone"
                        id="another_phone"
                        value={formData.another_phone}
                        onChange={(e) => handlePhoneChange(e, 'another_phone')}
                        pattern="^\+20[0-9]{10}$" // Ensures another phone starts with +20 and has 10 digits
                        className={`border ${errors.another_phone ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12 `}
                    />
                    {errors.another_phone && <label className="text-red-500 text-sm mt-1" htmlFor="another_phone">{errors.another_phone}</label>}
                </div>
            </div>

            <div className='flex flex-col lg:flex-row justify-between space-y-5 lg:space-y-0'>
                {/* landline */}
                <div className="w-full lg:w-[45%]">
                    <label className="block text-sm font-medium pb-3" htmlFor="landline">رقم الهاتف الأرضي</label>
                    <input
                        type="text"
                        name="landline"
                        id="landline"
                        value={formData.landline}
                        onChange={handleChange}
                        className={`border ${errors.landline ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12`}
                    />
                    {errors.landline && <label className="text-red-500 text-sm mt-1" htmlFor="landline">{errors.landline}</label>}
                </div>

                {/* Job */}
                <div className="w-full lg:w-[45%]">
                    <label className="block text-sm font-medium pb-3" htmlFor="job">
                        الوظيفة <span className="text-red-500">*</span></label>
                    <select
                        name="job"
                        id="job"
                        value={formData.job}
                        onChange={handleChange}
                        className={`border ${errors.job ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12`}
                    >
                        <option value='0'>أختر الوظيفة</option>
                        {isJobs?.map((job) => ( // Assuming `isJobs` is an array of job objects
                            <option key={job.id} value={job.id}>{job.title}</option>
                        ))}
                    </select>
                    {errors.job && <label className="text-red-500 text-sm mt-1" htmlFor="job">{errors.job}</label>}
                </div>
            </div>

            <div className="border border-gray-300 rounded shadow-sm p-4">
                <div
                    className="cursor-pointer flex justify-between items-center"
                    onClick={() => setIsAddressCollapsed(!isAddressCollapsed)}
                >
                    <h2 className="font-medium">عنوان السكن</h2>
                    <button type="button" className='text-sm'>
                        {isAddressCollapsed ? '▼' : '▲'}
                    </button>
                </div>

                {!isAddressCollapsed && (
                    <div className="mt-4 space-y-4 py-2">
                        <div className="flex flex-col lg:flex-row justify-between space-y-5 lg:space-y-0">
                            <div className="w-full lg:w-[10%]">
                                <label className="block text-sm font-medium pb-3" htmlFor="house_number">
                                    رقم المنزل <span className="text-red-500">*</span></label>
                                <input
                                    type="text"
                                    name="house_number"
                                    id="house_number"
                                    value={formData.house_number}
                                    onChange={handleChange}
                                    className={`border ${errors.house_number ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12`}
                                />
                                {errors.house_number && <label className="text-red-500 text-sm mt-1" htmlFor="house_number">{errors.house_number}</label>}
                            </div>
                            <div className="w-full lg:w-[20%]">
                                <label className="block text-sm font-medium pb-3" htmlFor="street">
                                    الشارع <span className="text-red-500">*</span></label>
                                <input
                                    type="text"
                                    name="street"
                                    id="street"
                                    value={formData.street}
                                    onChange={handleChange}
                                    className={`border ${errors.street ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12`}
                                />
                                {errors.street && <label className="text-red-500 text-sm mt-1" htmlFor="street">{errors.street}</label>}

                            </div>
                            <div className="w-full lg:w-[20%]">
                                <label className="block text-sm font-medium pb-3" htmlFor="district">الحي</label>
                                <input
                                    type="text"
                                    name="district"
                                    id="district"
                                    value={formData.district}
                                    onChange={handleChange}
                                    className="border border-gray-300 focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12"
                                />
                            </div>
                            <div className="w-full lg:w-[20%]">
                                <label className="block text-sm font-medium pb-3" htmlFor="city">
                                    المدينة <span className="text-red-500">*</span></label>
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    className={`border ${errors.city ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12`}
                                />
                                {errors.city && <label className="text-red-500 text-sm mt-1" htmlFor="city">{errors.city}</label>}
                            </div>
                            <div className="w-full lg:w-[20%]">
                                <label className="block text-sm font-medium pb-3" htmlFor="client_governorate">
                                    المحافظة <span className="text-red-500">*</span></label>
                                <select
                                    name="client_governorate"
                                    id="client_governorate"
                                    value={formData.client_governorate}
                                    onChange={handleChange}
                                    className={`border ${errors.client_governorate ? 'border-red-500' : 'border-gray-300'} h-12 focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12`}
                                >
                                    <option value='0'>أختر المحافظة</option>
                                    {isGovernorates?.map((client_governorate) => ( // Assuming `isJobs` is an array of job objects
                                        <option key={client_governorate.id} value={client_governorate.id}>{client_governorate.title}</option>
                                    ))}
                                </select>
                                {errors.client_governorate && <label className="text-red-500 text-sm mt-1" htmlFor="client_governorate">{errors.client_governorate}</label>}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Client;
