import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import banner1 from '../../assets/banner1.jpg';
import banner2 from '../../assets/banner2.jpg';
import banner3 from '../../assets/banner3.jpg';

const HomeBanner = () => {
  // Sample data for carousel items
  const carouselItems = [
    {
      id: 1,
      image: banner1, // Correct image reference
      title: 'مبادرة وزارة المالية لدعم تحويل السيارات للعمل بالغاز الطبيعي',
      description: 'مبادرة لتطوير السيارات وتحويلها للعمل بالغاز الطبيعي.',
    },
    {
      id: 2,
      image: banner2, // Correct image reference
      title: 'فرص جديدة لتوفير الطاقة وتقليل التلوث',
      description: 'مبادرة لتحويل السيارات لتقليل الانبعاثات وتحقيق الاستدامة.',
    },
    {
      id: 3,
      image: banner3, // Correct image reference
      title: 'دعم حكومي للتقنيات الصديقة للبيئة',
      description: 'تشجيع استخدام الغاز الطبيعي بدلاً من الوقود التقليدي.',
    },
  ];

  // Carousel responsive settings
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className='flex flex-col'>
      {/* Carousel Section */}
      <div className="w-full relative">
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          containerClass="carousel-container relative z-0"
          itemClass="carousel-item"
          arrows={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
        >
          {carouselItems.map(item => (
            <div
              key={item.id}
              className="relative flex items-center justify-center text-center overflow-hidden h-[40vh] md:h-[30vh] lg:h-[60vh] object-cover"
              style={{
                backgroundImage: `url(${item.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              aria-label={item.title}
            >
              {/* Overlay Text */}
              <div className="absolute bg-black bg-opacity-60 h-[60vh] w-full flex flex-col justify-center items-center text-white p-6">
                <div>
                  <h2 className="text-[18px] lg:text-[24px] xl:text-[32px] font-bold mb-4 lg:text-center md:text-center sm-text-end text-center select-none">
                    {item.title.split(' ').length > 6
                      ? item.title.split(' ').map((word, index) => (
                        <React.Fragment key={index}>
                          {word} {index === 5 ? <br /> : ' '}
                        </React.Fragment>
                      ))
                      : item.title}
                  </h2>
                  <p className="text-[16px] lg:text-[18px] mb-4 lg:text-center md:text-center sm-text-end text-center select-none">{item.description}</p>
                </div>

                {/* Static Buttons Under the Text */}
                <div className="flex justify-center items-center gap-4 mt-4">
                  <Link to="/inquiry?request_id=${requestNumber}&national_id=${nationalId}">
                    <button className="px-6 py-3 rounded-md bg-[#134659] hover:bg-[#0C2C38] text-sm lg:text-xl text-white w-[150px] lg:w-[200px] select-none">
                      إستعلام
                    </button>
                  </Link>
                  <Link to="/replacement">
                    <button className="px-6 py-3 bg-[#1B6333] text-white hover:bg-[#0C2B16] text-sm lg:text-xl rounded-md w-[150px] lg:w-[200px] select-none">
                      الإشتراك بالمبادرة
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default HomeBanner;
