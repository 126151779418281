import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { newBaseUrl } from "../Consts";

export const PreferencesContext = createContext();
export const usePreferences = () => {
    return useContext(PreferencesContext);
};

export default function PreferencesContextProvider({ children }) {
    const [isJobs, setIsJobs] = useState([]);
    const [isGovernorates, setIsGovernorates] = useState([]);
    const [isTrafficCenters, setIsTrafficCenters] = useState([]);
    const [isVehiclesBrands, setIsVehiclesBrands] = useState([]);
    const [isConversionCompanies, setIsConversionCompanies] = useState([]);
    const [isConversionCenters, setIsConversionCenters] = useState([]);
    const [isReplacementRequest, setIsReplacementRequest] = useState([]);

    const headers = {
        'Content-Type': 'application/json',
    };

    async function getJobs() {
        try {
            const res = await axios.get(`${newBaseUrl}/api/jobs`, { headers });
            // console.log(res.data);
            setIsJobs(res?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getTrafficCenters(gov_id) {
        try {
            const res = await axios.get(`${newBaseUrl}/api/traffic-centers/${gov_id}`, { headers });
            
            // Check if the response contains data, else set to empty array
            if (res?.data && res.data.length > 0) {
                setIsTrafficCenters(res.data);  // Set traffic centers with received data
            } else {
                setIsTrafficCenters([]);  // Set to empty if no data is returned
            }
        } catch (err) {
            console.error('Error fetching traffic centers:', err);
            
            // If there is an error (like 404), set traffic centers to empty array
            setIsTrafficCenters([]);  
        }
    }
    

    async function getGovernorates() {
        try {
            const res = await axios.get(`${newBaseUrl}/api/governorates`, { headers });
            // console.log(res.data);
            setIsGovernorates(res?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getConversionCompanies(gov_id) {
        try {
            const res = await axios.get(`${newBaseUrl}/api/conversion-companies/${gov_id}`, { headers });
            
            // console.log(res.data);
            setIsConversionCompanies(res?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getReplacementRequest(request_id, national_id) {
        try {
            const res = await axios.get(`${newBaseUrl}/api/replacement-request/${request_id}/${national_id}`, { headers });
            
            // console.log(res.data);
            setIsReplacementRequest(res?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getConversionCenters(gov_id, comp_id) {
        try {
            const res = await axios.get(`${newBaseUrl}/api/conversion-centers/${gov_id}/${comp_id}`, { headers });
            
            // console.log(res.data);
            setIsConversionCenters(res?.data);
        } catch (err) {
            console.error(err);
        }
    }
    async function getVehiclesBrands() {
        try {
            const res = await axios.get(`${newBaseUrl}/api/vehicles-brands`, { headers });
            // console.log(res.data);
            setIsVehiclesBrands(res?.data);
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <PreferencesContext.Provider value={{ isJobs, getJobs, getGovernorates, isGovernorates, getTrafficCenters, isTrafficCenters, 
        getVehiclesBrands, isVehiclesBrands, getConversionCompanies, isConversionCompanies, getConversionCenters, isConversionCenters, getReplacementRequest, isReplacementRequest  }}>
            {children}
        </PreferencesContext.Provider>
    );
}
