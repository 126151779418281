// src/components/StepsBar.js
import React from 'react';
import { FaCar, FaCheck, FaGasPump , FaPaperclip, FaPaperPlane, FaUser } from 'react-icons/fa6';
import { ImHammer2 } from 'react-icons/im';

const ProgressBar = ({ step }) => {
    const steps = [
        { label: 'بيانات العميل', icon: <FaUser /> },
        { label: 'بيانات المركبة', icon: <FaCar /> },
        { label: 'شركة التحويل', icon: <FaGasPump  /> },
        { label: 'المستندات', icon: <FaPaperclip /> },
        { label: 'الشروط والاحكام', icon: <ImHammer2  /> },
        { label: 'بيانات الطلب', icon: <FaCheck /> },
    ];

    return (
        <div className="flex items-center justify-between w-full px-3 md:px-[15%] py-5 mt-10">
            {steps.map((s, index) => (
                <div key={index} className="flex flex-col items-center">
                    <div className="relative flex items-center justify-center">
                        {/* Circle for the icon */}
                        <div className={`flex items-center justify-center w-16 h-16 rounded-full border-2 ${step < index + 1 ? 'border-gray-400' : 'border-[#1B6333]'}`}>
                            <div className={`text-xl ${step < index + 1 ? 'text-gray-400' : 'text-[#1B6333]'}`}>
                                {s.icon}
                            </div>
                        </div>    
                    </div>
                    <div className={`mt-1 ${step < index + 1 ? 'text-gray-400' : 'text-[#1B6333]'}`}>
                        {s.label}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProgressBar;
