import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#134659] text-white py-8 px-0 md:px-[5%] lg:px-[15%]">
      <div className="mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between">
          {/* Section 1: Company Info */}
          {/* <div className="mb-6 md:mb-0">
            <h3 className="font-bold text-lg">Company Name</h3>
            <p className="text-gray-400">
              Your company tagline or brief description goes here. You can also add the company’s mission or any other key details.
            </p>
          </div> */}

          {/* Section 2: Quick Links */}
          <div className="mb-6 md:mb-0">
            {/* <h3 className="font-bold text-lg mb-2">Quick Links</h3> */}
            <ul>
              <li><a href="#" className="text-gray-400 hover:text-white">الرئيسية</a></li>
              <li><a href="#" className="text-gray-400 hover:text-white">عن المبادرة</a></li>
              <li><a href="#" className="text-gray-400 hover:text-white">الشروط والمستندات</a></li>
              <li><a href="#" className="text-gray-400 hover:text-white">شركاء التنفيذ</a></li>
            </ul>
          </div>

          {/* Section 3: Contact Info */}
          <div className="mb-6 md:mb-0">
            <h3 className="font-bold text-lg mb-2">Contact Us</h3>
            <p className="text-gray-400">123 Street, City, Country</p>
            <p className="text-gray-400">Email: info@example.com</p>
            <p className="text-gray-400">Phone: (123) 456-7890</p>
          </div>

          {/* Section 4: Social Media */}
          <div className="flex flex-col">
            <h3 className="font-bold text-lg mb-2">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-white">
                <i className="fab fa-facebook-f"></i> {/* Replace with icons */}
              </a>
              <a href="#" className="text-gray-400 hover:text-white">
                <i className="fab fa-twitter"></i> {/* Replace with icons */}
              </a>
              <a href="#" className="text-gray-400 hover:text-white">
                <i className="fab fa-instagram"></i> {/* Replace with icons */}
              </a>
              <a href="#" className="text-gray-400 hover:text-white">
                <i className="fab fa-linkedin"></i> {/* Replace with icons */}
              </a>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="border-t border-white mt-6 pt-6 text-center">
          <p className="text-gray-400">&copy; {new Date().getFullYear()} جميع الحقوق محفوظة</p>
          <p className="text-gray-400">تمت البرمجة والتطوير بواسطة غازتك</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
