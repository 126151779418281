// src/components/Banner.js
import React, { useEffect, useState } from 'react';
import { usePreferences } from '../../Context/PreferencesContext';

const Vehicle = ({ formData, handleChange, errors }) => {
  const [selectedType, setSelectedType] = useState('numChar'); // Step 1: State for radio button selection
  const { getGovernorates, isGovernorates } = usePreferences();
  const { getTrafficCenters, isTrafficCenters } = usePreferences();
  const { getVehiclesBrands, isVehiclesBrands } = usePreferences();

  useEffect(() => {
      getGovernorates();
      getVehiclesBrands();
  }, []);

  return (
    <div className='px-3 md:px-[15%] py-5 space-y-10'>
            <h1 className="text-2xl font-bold">بيانات المركبة</h1>

      {/* Radio Buttons */}
      <div className='flex flex-col justify-center'>
        {/* Radio Buttons */}
        <div className="flex flex-row mb-5">
          <label className="block font-medium">
            رقم لوحة المركبة <span className="text-red-500">*</span></label>
          <div className='flex flex-row justify-between lg:w-[30%] w-[50%] mr-9'>
            <label>
              <input
                type="radio"
                name="plateType"
                value="numChar"
                checked={selectedType === 'numChar'}
                onChange={() => setSelectedType('numChar')}
              />
              <span className='font-medium mr-2'>رقم وحروف</span>
            </label>
            <label>
              <input
                type="radio"
                name="plateType"
                value="numOnly"
                checked={selectedType === 'numOnly'}
                onChange={() => setSelectedType('numOnly')}
              />
              <span className='font-medium mr-2'>أرقام فقط</span>
            </label>
          </div>
        </div>

        {/* Conditional Input Card: Letters and Numbers */}
        <div className='flex flex-col lg:flex-row justify-center gap-5 space-y-5 lg:space-y-0'>
          {/* Letters Input */}
          {selectedType === 'numChar' && (
            <div className="flex flex-row justify-between gap-5 w-full h-12 lg:w-[30%]">
              <div>
                <input
                  type="text"
                  name="plateLetter1"
                  id="plateLetter1"
                  value={formData.plateLetter1}
                  onChange={handleChange}
                  maxLength="1"
                  pattern="[\u0600-\u06FF]" // Ensures single Arabic letter input
                  className={`border ${errors.plateLetter1 ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12 `}
                />
                {errors.plateLetter1 && <label className="text-red-500 mt-1" htmlFor="plateLetter1">{errors.plateLetter1}</label>}
              </div>
              <div>
                <input
                  type="text"
                  name="plateLetter2"
                  id="plateLetter2"
                  value={formData.plateLetter2}
                  onChange={handleChange}
                  maxLength="1"
                  pattern="[\u0600-\u06FF]" // Ensures single Arabic letter input
                  className={`border ${errors.plateLetter2 ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12 `}
                />
                {errors.plateLetter2 && <label className="text-red-500 mt-1" htmlFor="plateLetter2">{errors.plateLetter2}</label>}
              </div>
              <div>
                <input
                  type="text"
                  name="plateLetter3"
                  id="plateLetter3"
                  value={formData.plateLetter3}
                  onChange={handleChange}
                  maxLength="1"
                  pattern="[\u0600-\u06FF]" // Ensures single Arabic letter input
                  className={`border ${errors.plateLetter3 ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12 `}
                />
                {errors.plateLetter3 && <label className="text-red-500 mt-1" htmlFor="plateLetter3">{errors.plateLetter3}</label>}
              </div>
            </div>
          )}

          {/* Numbers Input */}
          <div className="flex flex-col w-full h-12 lg:w-[20%]">
            <input
              type="text"
              name="plateNumbers"
              id="plateNumbers"
              value={formData.plateNumbers}
              onChange={handleChange}
              maxLength="4" // Egyptian plates typically have 4 numbers
              pattern="\d{4}" // Ensures input is 4 digits only
              className={`border ${errors.plateNumbers ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12 `}
            />
            {errors.plateNumbers && <label className="text-red-500 mt-1" htmlFor="plateNumbers">{errors.plateNumbers}</label>}
          </div>
        </div>
      </div>

      <div className='flex flex-col lg:flex-row justify-between space-y-5 lg:space-y-0'>
        <div className="w-full lg:w-[45%]">
          <label className="block font-medium pb-3" htmlFor="vehicle_governorate">
            المحافظة <span className="text-red-500">*</span></label>
          <select
            name="vehicle_governorate"
            id="vehicle_governorate"
            value={formData.vehicle_governorate}
            onChange={(event) => {
              handleChange(event);
              getTrafficCenters(event.target.value);
          }}
            className={`border ${errors.vehicle_governorate ? 'border-red-500' : 'border-gray-300'} h-12 focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12`}
          >
            <option value='0'>أختر المحافظة</option>
            {isGovernorates?.map((vehicle_governorate) => ( // Assuming `isJobs` is an array of job objects
              <option key={vehicle_governorate.id} value={vehicle_governorate.id}>{vehicle_governorate.title}</option>
            ))}
          </select>
          {errors.vehicle_governorate && <label className="text-red-500 mt-1" htmlFor="vehicle_governorate">{errors.vehicle_governorate}</label>}
        </div>

        {/* Traffic Center Dropdown (Dynamic) */}
        <div className="w-full lg:w-[45%]">
          <label className="block font-medium pb-3" htmlFor="traffic_center">
            وحده المرور التابع لها <span className="text-red-500">*</span></label>
          <select
            name="traffic_center"
            id="traffic_center"
            value={formData.traffic_center}
            onChange={handleChange}
            disabled={!formData.vehicle_governorate}  // Disable until a vehicle_governorate is selected
            className={`border ${errors.traffic_center ? 'border-red-500' : 'border-gray-300'} h-12 focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12`}
          >
            <option value="0">اختر وحدة المرور</option>
            {isTrafficCenters?.map((traffic_center) => ( // Assuming `isJobs` is an array of job objects
              <option key={traffic_center.id} value={traffic_center.id}>{traffic_center.title}</option>
            ))}
          </select>
          {errors.traffic_center && <label className="text-red-500 mt-1" htmlFor="traffic_center">{errors.traffic_center}</label>}
        </div>
      </div>

      {/* brand and Another brand */}
      <div className='flex flex-col lg:flex-row justify-between space-y-5 lg:space-y-0'>
        <div className="w-full lg:w-[45%]">
          <label className="block font-medium pb-3" htmlFor="vehicle_brand">
            ماركة المركبة <span className="text-red-500">*</span></label>
          <select
            name="vehicle_brand"
            id="vehicle_brand"
            value={formData.vehicle_brand}
            onChange={handleChange}
            className={`border ${errors.vehicle_brand ? 'border-red-500' : 'border-gray-300'} h-12 focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12`}
          >
            <option value='0'>أختر ماركة المركبة</option>
            {isVehiclesBrands?.map((vehicle_brand) => ( // Assuming `isJobs` is an array of job objects
              <option key={vehicle_brand.id} value={vehicle_brand.id}>{vehicle_brand.title}</option>
            ))}
          </select>
          {errors.vehicle_brand && <label className="text-red-500 mt-1" htmlFor="vehicle_brand">{errors.vehicle_brand}</label>}
        </div>

        <div className="w-full lg:w-[45%]">
          <label className="block font-medium pb-3" htmlFor="vehicle_type">
            نوع الترخيص <span className="text-red-500">*</span></label>

          <select
            name="vehicle_type"
            id="vehicle_type"
            value={formData.vehicle_type}
            onChange={handleChange}
            className={`border ${errors.vehicle_type ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12 `}
          >
            <option value="">اختر نوع الترخيص</option> {/* Default option */}
            <option value="ملاكي">ملاكي</option>
            <option value="أجرة">أجرة</option>
          </select>

          {errors.vehicle_type && <label className="text-red-500 mt-1" htmlFor="vehicle_type">{errors.vehicle_type}</label>}
        </div>

      </div>

      <div className='flex flex-col lg:flex-row justify-between space-y-5 lg:space-y-0'>
        {/* year */}
        <div className="w-full lg:w-[30%]">
          <label className="block font-medium pb-3" htmlFor="year">
            سنة الصنع <span className="text-red-500">*</span>
          </label>
          <select
            name="year"
            id="year"
            value={formData.year}
            onChange={handleChange}
            className={`border ${errors.year ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12 `}
          >
            <option value="">سنه الصنع</option> {/* Default option */}
            {Array.from({ length: 25 }, (_, index) => 2000 + index).map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
          {errors.year && <label className="text-red-500 mt-1" htmlFor="year">{errors.year}</label>}
        </div>

        {/* chassis_number */}
        <div className="w-full lg:w-[30%]">
          <label className="block font-medium pb-3" htmlFor="chassis_number">
            رقم الشاسيه <span className="text-red-500">*</span></label>
          <input
            type="text"
            name="chassis_number"
            id="chassis_number"
            value={formData.chassis_number}
            onChange={handleChange}
            className={`border ${errors.chassis_number ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12`}
          />
          {errors.chassis_number && <label className="text-red-500 mt-1" htmlFor="chassis_number">{errors.chassis_number}</label>}
        </div>
        {/* motor_number */}
        <div className="w-full lg:w-[30%]">
          <label className="block font-medium pb-3" htmlFor="motor_number">
            رقم الموتور <span className="text-red-500">*</span></label>
          <input
            type="text"
            name="motor_number"
            id="motor_number"
            value={formData.motor_number}
            onChange={handleChange}
            className={`border ${errors.motor_number ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12`}
          />
          {errors.motor_number && <label className="text-red-500 mt-1" htmlFor="motor_number">{errors.motor_number}</label>}
        </div>
      </div>
    </div>
  );
};

export default Vehicle;
