import React, { useState } from 'react';
import Client from '../Components/Replacement/Client';
import Vehicle from '../Components/Replacement/Vehicle';
import ProgressBar from '../Components/Replacement/ProgressBar';
import ConversionCenters from '../Components/Replacement/ConversionCenters';
import Terms from '../Components/Replacement/Terms&Conditions';
import { newBaseUrl } from '../Consts';
import axios from 'axios';
import Documents from '../Components/Replacement/Documents';
import Apply from '../Components/Replacement/Apply';

const Replacement = () => {
  const [formData, setFormData] = useState({
    national_id: '',
    national_id_end: '',
    phone: '+20',
    anotherPhone: '+20',
    email: '',
    fullname: '',
    landline: '',
    job: '',
    house_number: '',
    street: '',
    district: '',
    city: '',
    client_governorate: '',

    chassis_number: '',
    year: '',
    vehicle_type: '',
    vehicle_brand: '',
    traffic_center: '',
    vehicle_governorate: '',
    motor_number: '',

    plateLetter1: '',
    plateLetter2: '',
    plateLetter3: '',
    plateNumbers: '',

    company_governorate: '',
    conversion_company: '',
    conversion_center: '',

    file_f_national_id: '',
    file_r_national_id: '',
    file_f_car_license: '',
    file_r_car_license: '',

    terms: '',

  });

  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(1); // Step to manage which form is shown


  // Validate the form inputs
  const validate = (field, value) => {
    let errorMessage = '';

    if (step === 1) {

      if (field === 'national_id') {
        if (!value) {
          errorMessage = 'الرقم القومي مطلوب';
        } else if (!/^\d+$/.test(value)) {
          errorMessage = 'الرقم القومي يجب ألا يحتوي على أحرف. أرقام فقط';
        } else if (value.length !== 14) {
          errorMessage = "الرقم القومي غير صحيح";
        } else {
          // Extracting information for further validation
          const gen = parseInt(value.substr(0, 1));
          const year = parseInt(value.substr(1, 2));
          const month = parseInt(value.substr(3, 2));
          const day = parseInt(value.substr(5, 2));

          // Gender Check
          if (gen % 2 === 0) {
            // Female
            // Optional: You can save or use this information as needed
          } else {
            // Male
            // Optional: You can save or use this information as needed
          }

          // Birth Year Check
          if (year > 6 && gen === 3) {
            errorMessage = "الرقم القومي غير صحيح";
          }

          // Birth Month Check
          if (month < 1 || month > 12) {
            errorMessage = "الرقم القومي غير صحيح";
          }

          // Birth Day Check
          let daysInMonth;
          switch (month) {
            case 2:
              daysInMonth = 29; // You may want to handle leap years separately
              break;
            case 4:
            case 6:
            case 9:
            case 11:
              daysInMonth = 30;
              break;
            default:
              daysInMonth = 31;
              break;
          }

          if (day < 1 || day > daysInMonth) {
            errorMessage = "الرقم القومي غير صحيح";
          }
        }
      }

      // Validate End of National ID (Date)
      if (field === 'national_id_end') {
        if (!value) {
          errorMessage = 'تاريخ انتهاء الرقم القومي مطلوب';
        }
      }

      if (field === 'fullname') {
        if (!value) {
          errorMessage = 'الأسم مطلوب';
        }
      }

      if (field === 'house_number') {
        if (!value) {
          errorMessage = 'رقم المنزل مطلوب';
        }
      }

      if (field === 'street') {
        if (!value) {
          errorMessage = 'اسم الشارع مطلوب';
        }
      }

      if (field === 'city') {
        if (!value) {
          errorMessage = 'المدينة مطلوبة';
        }
      }

      if (field === 'client_governorate') {
        if (!value) {
          errorMessage = 'المحافظة مطلوبة';
        }
      }
      // Validate Phone Number: Must start with +20 and have 10 digits afterward
      if (field === 'phone') {
        if (value === '+20') {
          errorMessage = 'رقم الموبايل مطلوب';
        } else if (!/^\+20\d{10}$/.test(value)) {
          errorMessage = 'رقم الموبايل غير صحيح';
        }
      }

      if (field === 'anotherPhone') {
        if (value != '+20' && !/^\+20\d{10}$/.test(value)) {
          errorMessage = 'رقم الموبايل غير صحيح';
        }
      }

      // Validate Email
      if (field === 'email') {
        if (value && !/\S+@\S+\.\S+/.test(value)) {
          errorMessage = 'البريد الإلكتروني غير صالح';
        }
      }

      // Validate Landline
      if (field === 'landline') {
        if (value && !/^\d*$/.test(value)) {
          errorMessage = 'رقم الخط الأرضي يجب أن يتكون من أرقام فقط';
        }
      }

      // Validate Job field
      if (field === 'job') {
        if (!value.trim()) {
          errorMessage = 'الوظيفة مطلوبة';
        }
      }
    }

    if (step === 2) {
      if (field === 'plateLetter1') {
        if (!value.trim()) {
          errorMessage = 'حرف مطلوب';
        }
      }

      if (field === 'plateNumbers') {
        if (!value.trim()) {
          errorMessage = 'رقم مطلوب';
        }
      }

      if (field === 'vehicle_governorate') {
        if (!value.trim() || value === '0') {
          errorMessage = 'المحافظة مطلوبة';
        }
      }

      if (field === 'traffic_center') {
        if (!value.trim() || value === '0') {
          errorMessage = 'وحده المرور مطلوبة';
        }
      }

      if (field === 'vehicle_brand') {
        if (!value.trim() || value === '0') {
          errorMessage = 'ماركة المركبة مطلوبة';
        }
      }

      if (field === 'vehicle_type') {
        if (!value.trim() || value === '0') {
          errorMessage = 'نوع الترخيص مطلوب';
        }
      }

      if (field === 'year') {
        if (!value.trim() || value === '0') {
          errorMessage = 'سنه الصنع مطلوبة';
        }
      }

      if (field === 'chassis_number') {
        if (!value.trim() || value === '0') {
          errorMessage = 'رقم الشاسية مطلوب';
        }
      }

      if (field === 'motor_number') {
        if (!value.trim() || value === '0') {
          errorMessage = 'رقم الموتور مطلوب';
        }
      }
    }

    if (step === 3) {
      if (field === 'company_governorate') {
        if (!value.trim() || value === '0') {
          errorMessage = 'المحافظة مطلوبة';
        }
      }

      if (field === 'conversion_company') {
        if (!value.trim() || value === '0') {
          errorMessage = 'شركة التحويل مطلوبة';
        }
      }

      if (field === 'conversion_center') {
        if (!value.trim() || value === '0') {
          errorMessage = 'مركز التحويل مطلوب';
        }
      }
    }

    if (step === 5) {
      if (field === 'terms') {
        if (!value && value === false) {
          errorMessage = 'برجاء الموافقة على الشروط والاحكام';
        }
      }
    }

    if (step === 4) {
      if (field === 'file_f_national_id') {
        if (!value) {
          errorMessage = 'الوجهة الامامي للبطاقة مطلوب';
        }
      }

      if (field === 'file_r_national_id') {
        if (!value) {
          errorMessage = 'الوجهة الخلفى للبطاقة مطلوب';
        }
      }

      if (field === 'file_f_car_license') {
        if (!value) {
          errorMessage = 'الوجهة الامامي للرخصة مطلوب';
        }
      }

      if (field === 'file_r_car_license') {
        if (!value) {
          errorMessage = 'الوجهة الخلفى للرخصة مطلوب';
        }
      }
    }
    return errorMessage || null;
  };

  // Handle input change with validation
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'company_governorate') {
      // Reset conversion company and center when governorate changes
      setFormData((prevData) => ({
        ...prevData,
        company_governorate: value,
        conversion_company: '', // Reset company to show "اختر شركة"
        conversion_center: '',  // Reset center to show "اختر مركز التحويل"
      }));
    } else if (name === 'conversion_company') {
      // Reset conversion center when company changes
      setFormData((prevData) => ({
        ...prevData,
        conversion_company: value,
        conversion_center: '', // Reset center to show "اختر مركز التحويل"
      }));
    } else {
      // Update other fields
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    // Validate field on change and set error state
    const error = validate(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };


  // Handle form submission
  const handleSave = async () => {
    const newErrors = {};

    // Validate all fields
    Object.keys(formData).forEach((field) => {
      const error = validate(field, formData[field]);
      if (error) {
        newErrors[field] = error; // Collect validation errors
      }
    });

    // Check if there are any validation errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); // Show errors if validation fails
      return;
    }
    console.log(formData);
    // Form is valid, send data to API
    try {
      const response = await axios.post(`${newBaseUrl}/api/replacement-requests`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Check the response status
      if (response.status === 201) {
        console.log('Request successfully submitted:', response.data);
        alert('Request successfully submitted, Request Number is : ', response.data['request_id']);
      } else {
        alert('Error in submitting request.');
      }
    } catch (error) {
      // Log the error response to see the validation errors
      if (error.response) {
        console.error('Validation errors:', error.response.data.errors);
        alert('Error in submitting form. Please check the console for details.');
      } else {
        console.error('Error in submitting form:', error.message);
        alert('Error in submitting form. Please try again later.');
      }
    }
  };


  const handleNext = () => {
    const newErrors = {};

    // Validate all fields in the current step
    Object.keys(formData).forEach((field) => {
      const error = validate(field, formData[field]);
      if (error) {
        newErrors[field] = error;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); // Show errors if validation fails
      return;
    }

    // If validation passes, move to the next step
    setStep((prevStep) => prevStep + 1);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePrev = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1)); // Ensure step doesn't go below 1

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <ProgressBar step={step} />
      {step === 1 && (
        <div>
          <Client
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            handleChange={handleChange}
          />
          <span className='px-3 md:px-[15%] py-10 space-y-10 text-red-500 text-lg'>تنويه: الحقول التي بجانبها (*) حقول إجبارية.
          </span>
        </div>

      )}
      {step === 2 && (
        <div>
          <Vehicle
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            handleChange={handleChange}
          />
          <span className='px-3 md:px-[15%] py-10 space-y-10 text-red-500 text-lg'>تنويه: الحقول التي بجانبها (*) حقول إجبارية.
          </span>
        </div>

      )}
      {step === 3 && (
        <div>
          <ConversionCenters
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            handleChange={handleChange}
          />
          <span className='px-3 md:px-[15%] py-10 space-y-10 text-red-500 text-lg'>تنويه: الحقول التي بجانبها (*) حقول إجبارية.
          </span>
        </div>
      )}
      {step === 4 && (
        <Documents
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          handleChange={handleChange}
        />
      )}
      {step === 5 && (
        <Terms
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          handleChange={handleChange}
        />
      )}
      {step === 6 && (
        <Apply
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          handleChange={handleChange}
        />
      )}

      <div className='flex flex-col lg:flex-row justify-between items-center gap-5 py-10 px-0 md:px-[15%]'>
        {step < 5 && (
          <button
            onClick={handleNext}
            className="bg-[#1B6333] w-full lg:w-[15%] text-white px-4 py-2 rounded hover:bg-[#2A994F]">
            التالي
          </button>
        )}
        {step === 5 && (
          <button
            onClick={handleSave}
            className="bg-[#1B6333] w-full lg:w-[15%] text-white px-4 py-2 rounded hover:bg-[#2A994F]">
            تقديم الطلب
          </button>
        )}
        {step > 1 && (
          <button
            onClick={handlePrev}
            className="bg-[#C81818] w-full lg:w-[15%] text-white px-4 py-2 rounded hover:bg-[#E31B1B]">
            السابق
          </button>
        )}

      </div>

    </div>
  );
};

export default Replacement;
