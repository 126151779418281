import React from 'react';
import { Link } from 'react-router-dom';
import img from '../../assets/banner-img-1.jpg';

const HomeSecondSection = () => {
  return (
    <div className="flex flex-col lg:flex-row lg:justify-between md:flex-row items-center gap-5 px-5 md:px-[5%] lg:px-[15%]">
      <div data-aos="fade-left">
        <img src={img} alt="" className="w-full rounded-md select-none" />
      </div>
      <div className="flex flex-col w-[85vw] lg:w-[51vw] gap-2">
        <div
          data-aos="fade-right"
          className="relative lg:services-title flex flex-col items-start "
        >
          <h1 className="text-[#134659] text-[14px] lg:text-[24px] xl:text-[30px] font-[400] select-none">
            معلومات
          </h1>
          <h1 className="text-[#1B6333] text-[18px] lg:text-[28px] xl:text-[38px] font-[600] select-none">
            عن المبادرة
          </h1>
        </div>
        <div
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="300"
          data-aos-offset="0"
          className="flex flex-col items-start gap-5"
        >
          {/* <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[400]">
          عن المبادرة
          </p> */}
          <p className="text-start text-[#141414] text-[14px] sm:text-[16px] md:text-[14px] lg:text-[14px] xl:text-[20px] font-[400] select-none" style={{ textAlign: "justify" }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
        <Link data-aos="fade-right" className="me-auto" to={"/about"}>
          <button className="mt-5 py-2 px-6 rounded-md border-[1px] border-[#1B6333] text-[#1B6333] hover:text-[#ffffff] hover:bg-[#1B6333] text-[13px] lg:text-[20px] font-[400] w-[125px] lg:w-[150px]">
            أعرف اكتر
          </button>
        </Link>
      </div>
    </div>
  );
};

export default HomeSecondSection;
