import React, { useEffect, useState } from 'react';

const Terms = ({ formData, handleChange, errors }) => {
  const [isChecked, setIsChecked] = useState(formData.terms || false);

  // Update the checkbox state whenever formData.terms changes
  useEffect(() => {
    setIsChecked(formData.terms || false);
  }, [formData.terms]);

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    handleChange({ target: { name: 'terms', value: newCheckedState } }); // Update formData
  };

  return (
    <div className='px-0 md:px-[15%] py-10 space-y-10 text-right'>
      <h1 className="text-2xl font-bold">الشروط والأحكام</h1>
      <div className="space-y-4">
        <p>
          الرجاء قراءة هذه الشروط والأحكام بعناية قبل استخدام هذا الموقع.
          من خلال الوصول إلى الموقع أو استخدامه، فإنك توافق على الالتزام بهذه الشروط.
          إذا كنت لا توافق على جميع الشروط، فلا يجوز لك الوصول إلى الموقع.
        </p>
        {/* يمكنك إضافة المزيد من التفاصيل هنا */}
      </div>

      <div className="space-y-4">
        <div className="flex items-center justify-start py-5">
          <input
            type="checkbox"
            id="terms"
            name='terms'
            checked={isChecked}
            onChange={handleCheckboxChange}
            className="ml-2"
          />
          <label htmlFor="terms" className="text-md ml-2">
            أوافق على الشروط والأحكام <span className="text-red-500">*</span>
          </label>
        </div>
        {errors.terms && <label className="text-red-500 text-sm mt-1" htmlFor="terms">{errors.terms}</label>}
      </div>
    </div>
  );
};

export default Terms;
