// src/components/Banner.js
import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Apply from '../Components/Replacement/Apply';

const Inquiry = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const requestId = searchParams.get('request_id');
  const nationalId = searchParams.get('national_id');

  useEffect(() => {
    // Remove parameters from the URL
    const params = new URLSearchParams(searchParams);
    params.delete('request_id');
    params.delete('national_id');

    // Update the URL without the query parameters
    navigate({ search: params.toString() }, { replace: true });
  }, [searchParams, navigate]);

  return (
    <div>
      <Apply request_id={requestId} national_id={nationalId} />
    </div>
  );
};

export default Inquiry;
