import React from 'react';
import './index.css'; // Ensures Tailwind is loaded
import Header from './Layout/header';
import Home from './Pages/home';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import Footer from './Layout/footer';
import Replacement from './Pages/Replacement';
import PreferencesContextProvider from './Context/PreferencesContext';
import Inquiry from './Pages/Inquiry';
import Features from './Components/Home/HomeFeatures';
import Services from './Pages/Terms';

// Layout component to wrap Header and Outlet
const Layout = () => (
  <>
    <Header />
    <Outlet /> {/* This renders the matched child route */}
    <Footer />
  </>
);

function App() {
  const routers = createBrowserRouter([
    {
      path: "/", // Root path
      element: <Layout />, // Apply layout with header
      children: [
        {
          path: "", // Default route (no additional path)
          element: <Home />, // Home page renders at "/"
        },
        {
          path: "/replacement", // Default route (no additional path)
          element: <Replacement />, // Home page renders at "/"
        },
        {
          path: "/inquiry", // Default route (no additional path)
          element: <Inquiry />, // Home page renders at "/"
        },
        {
          path: "/partners", // Default route (no additional path)
          element: <Features />, // Home page renders at "/"
        },
        {
          path: "/terms", // Default route (no additional path)
          element: <Services />, // Home page renders at "/"
        },
        // Additional routes can go here, e.g. About, Contact, etc.
      ],
    },
  ]);

  return (
    <div className="App">
      <PreferencesContextProvider>
        <RouterProvider router={routers}></RouterProvider>
      </PreferencesContextProvider>
    </div>
  );
}

export default App;
