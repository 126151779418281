import React, { useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import gastec from '../../assets/gastec-logo.png';
import cargas from '../../assets/cargas-logo.png';
import master from '../../assets/mastergaseg_logo.jpg';
import egas from '../../assets/logo2.png';
import gov from '../../assets/logo1.png';
import arabia from '../../assets/arabia.jpg';

function HomePartners({ isHome }) {

    const carouselItems = [
        {
            id: 1,
            image: gastec, // Correct image reference
        },
        {
            id: 2,
            image: cargas, // Correct image reference
        },
        {
            id: 3,
            image: master, // Correct image reference
        },
        {
            id: 4,
            image: arabia, // Correct image reference
        },
        {
            id: 5,
            image: gov, // Correct image reference
        },
        {
            id: 6,
            image: egas, // Correct image reference
        },
    ];

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
            slidesToSlide: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 600 },
            items: 3,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 600, min: 0 },
            items: 3,
            slidesToSlide: 1,
        },
    };

    return (
        <div className="relative">
            <div
                className="py-5 px-5 md:px-[5%] lg:px-[15%]"
                style={{
                    backgroundImage: `url()`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div className="lg:banner lg:banner1 flex-col items-center">
                    {/* Title */}
                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-easing="ease-in-back"
                        data-aos-delay="100"
                        data-aos-offset="0"
                        className="relative text-[#1B6333] text-[18px] lg:text-[28px] xl:text-[38px] font-[400] leading-[120%] pb-5"
                    >
                        شركاء التنفيذ
                    </h1>

                    {/* Carousel */}
                    <div
                        data-aos="fade-left"
                        data-aos-delay="2000"
                        className="justify-center lg:flex-row lg:mx-0 gap-[2rem] lg:gap-[2rem] lg:my-5 overflow-hidden"
                        dir="ltr"
                    >
                        <Carousel
                            responsive={responsive}
                            infinite={carouselItems.length > 1}
                            autoPlay={true}
                            autoPlaySpeed={2000}
                            containerClass="carousel-container"
                            itemClass="carousel-item"
                            arrows={false}
                        >
                            {carouselItems?.map((data, index) => (
                                <img
                                    key={`partner-${index}`}
                                    src={data.image}
                                    className="w-[100px] h-[100px] lg:h-[150px] lg:w-[150px] object-contain mx-auto"
                                    alt={`Partner ${index + 1}`}
                                />
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePartners;
