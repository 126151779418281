import React, { useState } from "react";
import car from '../assets/car.jpg';
import car2 from '../assets/car2.png';
import car3 from '../assets/Car3.png';

const services = [
  {
    title: "ان المشارك يمتلك سيارة",
    image: car,
  },
  {
    title: "ان تكون السيارة غير محولة للغاز الطبيعي من قبل",
    image: car2,
  },
  {
    title: "ان تكون رخصة السيارة سارية",
    image: car3,
  },
];

const Services = () => {

  return (
    <div className="services-box bg-cover bg-center">
      <div className="mx-auto px-5 md:px-[5%] lg:px-[15%]">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <div
              key={index}
              className="relative group"
            >

              <div
                className="absolute inset-0 bg-cover h-[250px] bg-center rounded-lg"
                style={{
                  backgroundImage: `url(${service.image})`,
                }}
              ></div>
              <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-65 transition-opacity duration-200 rounded-lg"></div>
              <div className="relative z-10 text-white p-6 rounded-lg h-[250px] flex flex-col justify-center items-center text-center">
              <h3 className="text-2xl font-bold mb-4 select-none">{service.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
