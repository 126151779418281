// src/components/Replacement/Apply.js
import React, { useEffect } from 'react';
import { usePreferences } from '../../Context/PreferencesContext';
import { useSearchParams, useNavigate } from 'react-router-dom';


const Apply = ({ request_id, national_id }) => {
    const { getReplacementRequest, isReplacementRequest } = usePreferences();
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        // Ensure request_id and national_id are available before calling
        if (request_id && national_id) {
            getReplacementRequest(request_id, national_id);
        } else {
            navigate('/'); // Navigate to the home page if either is null
        }
    }, []); 

    return (
        <div className='px-0 md:px-[15%] py-10 space-y-10 text-right'>
            <h1 className="text-2xl font-bold">بيانات الطلب رقم # {isReplacementRequest.request_id}</h1>
            <h1 className="text-2xl font-bold text-[#1B6333]">حالة الطلب : {isReplacementRequest.status ? isReplacementRequest.status.title : 'غير متوفر'}</h1>


            {/* Section: Personal Information */}
            <div className="space-y-6 p-6 bg-white shadow-md rounded-lg">
                <h2 className="text-xl font-semibold border-b pb-2">بيانات العميل</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div><span className="font-bold">الاسم رباعي : </span>{isReplacementRequest.fullname}</div>
                    <div><span className="font-bold">البريد الإلكتروني : </span>{isReplacementRequest.email || 'غير متوفر'}</div>
                    <div><span className="font-bold">الرقم القومي : </span>{isReplacementRequest.national_id}</div>
                    <div><span className="font-bold">تاريخ انتهاء الرقم القومي : </span>{isReplacementRequest.national_id_end}</div>
                    <div><span className="font-bold">رقم الموبايل : </span>{isReplacementRequest.phone || 'غير متوفر'}</div>
                    <div><span className="font-bold">رقم موبايل اضافى: </span>{isReplacementRequest.another_phone || 'غير متوفر'}</div>
                    <div><span className="font-bold">رقم الهاتف الأرضي: </span>{isReplacementRequest.landline || 'غير متوفر'}</div>
                    <div><span className="font-bold">الوظيفة: </span>{isReplacementRequest.job ? isReplacementRequest.job.title : 'غير متوفر'}</div>
                </div>
            </div>

            {/* Section: Address Information */}
            <div className="space-y-6 p-6 bg-white shadow-md rounded-lg">
                <h2 className="text-xl font-semibold border-b pb-2">عنوان السكن</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div><span className="font-bold">رقم المنزل : </span>{isReplacementRequest.house_number}</div>
                    <div><span className="font-bold">الشارع : </span>{isReplacementRequest.street || 'غير متوفر'}</div>
                    <div><span className="font-bold">الحي : </span>{isReplacementRequest.district || 'غير متوفر'}</div>
                    <div><span className="font-bold">المدينة : </span>{isReplacementRequest.city || 'غير متوفر'}</div>
                    <div><span className="font-bold">المحافظة : </span>{isReplacementRequest.client_governorate ? isReplacementRequest.client_governorate.title : 'غير متوفر'}</div>
                </div>
            </div>

            {/* Section: Vehicle Information */}
            <div className="space-y-6 p-6 bg-white shadow-md rounded-lg">
                <h2 className="text-xl font-semibold border-b pb-2">بيانات المركبة</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <span className="font-bold">رقم لوحة المركبة : </span>
                        {isReplacementRequest.plateLetter1 ? isReplacementRequest.plateLetter1 + ' ' : '' + 
                         (isReplacementRequest.plateLetter2 ? isReplacementRequest.plateLetter2 + ' ' : '') + 
                         (isReplacementRequest.plateLetter3 ? isReplacementRequest.plateLetter3 + ' ' : '') + 
                         isReplacementRequest.plateNumbers}
                    </div>
                    <div><span className="font-bold">المحافظة : </span>{isReplacementRequest.vehicle_governorate ? isReplacementRequest.vehicle_governorate.title : 'غير متوفر'}</div>
                    <div><span className="font-bold">ماركة المركبة : </span>{isReplacementRequest.vehicle_brand ? isReplacementRequest.vehicle_brand.title : 'غير متوفر'}</div>
                    <div><span className="font-bold">السنة: </span>{isReplacementRequest.year || 'غير متوفر'}</div>
                    <div><span className="font-bold">وحده المرور التابع لها : </span>{isReplacementRequest.traffic_center ? isReplacementRequest.traffic_center.title : 'غير متوفر'}</div>
                    <div><span className="font-bold">نوع الترخيص : </span>{isReplacementRequest.vehicle_type || 'غير متوفر'}</div>
                    <div><span className="font-bold">رقم الشاسية : </span>{isReplacementRequest.chassis_number || 'غير متوفر'}</div>
                    <div><span className="font-bold">رقم الموتور : </span>{isReplacementRequest.motor_number || 'غير متوفر'}</div>
                </div>
            </div>

            {/* Section: Conversion Company Information */}
            <div className="space-y-6 p-6 bg-white shadow-md rounded-lg">
                <h2 className="text-xl font-semibold border-b pb-2">شركة التحويل</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div><span className="font-bold">محافظة الشركة: </span>{isReplacementRequest.company_governorate ? isReplacementRequest.company_governorate.title : 'غير متوفر'}</div>
                    <div><span className="font-bold">اسم الشركة: </span>{isReplacementRequest.conversion_company ? isReplacementRequest.conversion_company.title : 'غير متوفر'}</div>
                    <div><span className="font-bold">مركز التحويل: </span>{isReplacementRequest.conversion_center ? isReplacementRequest.conversion_center.title : 'غير متوفر'}</div>
                </div>
            </div>
        </div>
    );
};

export default Apply;
