import React, { useEffect, useState } from 'react';
import { usePreferences } from '../../Context/PreferencesContext';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import gastec from '../../assets/gastec-logo.png';
import cargas from '../../assets/cargas-logo.png';

const ConversionCenters = ({ formData, handleChange, errors }) => {
    const { getGovernorates, isGovernorates } = usePreferences();
    const { getConversionCompanies, isConversionCompanies } = usePreferences();
    const { getConversionCenters, isConversionCenters } = usePreferences();
    const [userLocation, setUserLocation] = useState({ lat: null, lng: null });
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [mapCenter, setMapCenter] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(5);

    useEffect(() => {
        getGovernorates();
        // Get user's current location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error("Error getting user location:", error);
                    setUserLocation({ lat: 30.1145918, lng: 31.3062344 }); // Default location
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
            setUserLocation({ lat: 30.1145918, lng: 31.3062344 }); // Default location
        }
    }, []);

    const mapContainerStyle = {
        height: '400px',
    };

    const defaultCenter = {
        lat: userLocation.lat !== null ? userLocation.lat : 26.8206,
        lng: userLocation.lng !== null ? userLocation.lng : 30.8025,
    };

    return (
        <div className='px-3 md:px-[15%] py-5 space-y-10'>
            <h1 className="text-2xl font-bold">شركة التحويل</h1>
            <div className='flex flex-col lg:flex-row justify-between space-y-5 lg:space-y-0'>
                <div className='w-full lg:w-[45%] space-y-10'>
                    {/* Governorate Select */}
                    <div className="w-full">
                        <label className="block font-medium pb-3" htmlFor="company_governorate">
                            المحافظة <span className="text-red-500">*</span></label>
                        <select
                            name="company_governorate"
                            id="company_governorate"
                            value={formData.company_governorate}
                            onChange={(event) => {
                                handleChange(event);
                                setSelectedCenter(null)
                                getConversionCompanies(event.target.value);
                            }}
                            className={`border ${errors.company_governorate ? 'border-red-500' : 'border-gray-300'} h-12 focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12`}
                        >
                            <option value='0'>أختر المحافظة</option>
                            {isGovernorates?.map((company_governorate) => (
                                <option key={company_governorate.id} value={company_governorate.id}>{company_governorate.title}</option>
                            ))}
                        </select>
                        {errors.company_governorate && <label className="text-red-500 mt-1">{errors.company_governorate}</label>}
                    </div>

                    {/* Conversion Company Select */}
                    <div className="w-full">
                        <label className="block text-sm font-medium pb-3" htmlFor="conversion_company">
                            شركة التحويل <span className="text-red-500">*</span></label>
                        <select
                            name="conversion_company"
                            id="conversion_company"
                            value={formData.conversion_company}
                            onChange={(event) => {
                                handleChange(event);
                                setSelectedCenter(null)
                                getConversionCenters(formData.company_governorate, event.target.value);
                            }}
                            disabled={!formData.company_governorate}
                            className={`border ${errors.conversion_company ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12 `}
                        >
                            <option value="">اختر شركة</option>
                            {isConversionCompanies.map((ConversionCompany) => (
                                <option key={ConversionCompany.id} value={ConversionCompany.id}>{ConversionCompany.title}</option>
                            ))}
                        </select>
                        {errors.conversion_company && <label className="text-red-500 text-sm mt-1">{errors.conversion_company}</label>}
                    </div>

                    {/* Conversion Center Select */}
                    <div className="w-full">
                        <label className="block text-sm font-medium pb-3" htmlFor="conversion_center">
                            مركز التحويل <span className="text-red-500">*</span></label>
                        <select
                            name="conversion_center"
                            id="conversion_center"
                            value={formData.conversion_center}
                            onChange={(event) => {
                                const selectedId = event.target.value;
                                handleChange(event);
                                const selectedCenter = isConversionCenters.find(center => center.id.toString() === selectedId);

                                if (selectedCenter) {
                                    setSelectedCenter(selectedCenter); // Set the selected center
                                    setMapCenter({ lat: Number(selectedCenter.latitude), lng: Number(selectedCenter.longitude) });
                                    setZoomLevel(15); // Zoom in when a center is selected
                                } else {
                                    setSelectedCenter(null); // Clear selection if no center is found
                                }
                            }}
                            disabled={!formData.company_governorate || !formData.conversion_company}
                            className={`border ${errors.conversion_center ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-[#1B6333] focus:border-2 rounded px-3 py-2 w-full h-12 `}
                        >
                            <option value="">اختر مركز التحويل</option>
                            {isConversionCenters.map((ConversionCenter) => (
                                <option key={ConversionCenter.id} value={ConversionCenter.id}>{ConversionCenter.title}</option>
                            ))}
                        </select>
                        {errors.conversion_center && <label className="text-red-500 text-sm mt-1">{errors.conversion_center}</label>}
                    </div>
                </div>

                {/* Google Map */}
                <div className="w-full lg:w-[45%]">
                    <LoadScript googleMapURL="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&v=3.exp&libraries=geometry,drawing,places"> {/* Replace with your actual API key */}
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={mapCenter || defaultCenter}
                            zoom={zoomLevel}
                        >
                            {isConversionCenters.map((ConversionCenter) => (
                                <Marker
                                    key={ConversionCenter.id}
                                    position={{
                                        lat: Number(ConversionCenter.latitude),
                                        lng: Number(ConversionCenter.longitude),
                                    }}
                                    onClick={() => setSelectedCenter(ConversionCenter)} // Set the selected center on click
                                />
                            ))}

                            {/* InfoWindow for displaying selected center details */}
                            {selectedCenter && (
                                <InfoWindow
                                    position={{
                                        lat: Number(selectedCenter.latitude),
                                        lng: Number(selectedCenter.longitude),
                                    }}
                                    onCloseClick={() => setSelectedCenter(null)} // Clear selection on close
                                >
                                    <div className="flex flex-col p-2 items-center" style={{ fontFamily: 'Cairo, sans-serif' }}>
                                        {/* Add an image at the top */}
                                        <img
                                            src={selectedCenter.comp_id == 1 ? gastec : selectedCenter.comp_id == 2 ? cargas : null} // Assuming the image URL is stored in `selectedCenter.imageUrl`
                                            alt={selectedCenter.title}
                                            className="w-28 h-28 object-containmb-2" // Tailwind classes for styling
                                        />

                                        <h2 className="font-bold">{selectedCenter.title}</h2>
                                        <p>{selectedCenter.address}</p>
                                        <a
                                            target='_blank'
                                            href={`https://www.google.com/maps/dir/?api=1&origin=${userLocation.lat},${userLocation.lng}&destination=${selectedCenter.latitude},${selectedCenter.longitude}`}
                                            className="text-blue-500"
                                        >
                                            الطريق الى مركز التحويل
                                        </a>
                                    </div>
                                </InfoWindow>
                            )}
                        </GoogleMap>
                    </LoadScript>
                </div>
            </div>
        </div>
    );
};

export default ConversionCenters;
