// src/components/Banner.js
import React, { useState } from 'react';
import HomeSecondSection from '../Components/Home/SecondSection';
import HomeBanner from '../Components/Home/HomeBanner';
import HomePartners from '../Components/Home/HomePartners';
import Features from '../Components/Home/HomeFeatures';
import Services from './Terms';

const Home = () => {

  return (
    <div className='space-y-14'>
      <HomeBanner />
      <HomeSecondSection />
      <Services />
      <Features />
      <HomePartners />
    </div>
  );
};

export default Home;
