import React, { useState, useEffect } from 'react';
import logo1 from '../assets/logo1.png';  // Import the first logo
import logo2 from '../assets/logo2.png';  // Import the second logo
import logo3 from '../assets/fiLogo.png';  // Import the second logo
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 50) {
  //       setIsScrolled(true);
  //     } else {
  //       setIsScrolled(false);
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <header className="bg-white text-[#1B6333] py-2 relative sticky top-0 z-30 shadow-md px-5 md:px-[5%] lg:px-[15%] transition-all duration-700">

      {/* Blur Effect Overlay */}
      {isOpen && (
        <div onClick={toggleMenu} className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-[1px] z-10 "></div>
      )}

      {/* Mobile Navigation - toggles with hamburger menu */}
      <nav className={`fixed top-0 left-0 w-64 h-full bg-white p-4 transition-transform duration-700 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:hidden z-20`}>

        {/* Close Button (X) */}
        <button onClick={toggleMenu} className="absolute top-8 left-4 text-[#1B6333] focus:outline-none">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>

        {/* Right-Aligned Menu Items */}
        <ul className="space-y-4 mt-10 text-right">
          <li><a href="/" onClick={toggleMenu} className="block hover:text-[#2B9E51] font-semibold">الرئيسية</a></li>
          <li><a href="#about" onClick={toggleMenu} className="block hover:text-[#2B9E51] font-semibold">عن المبادرة</a></li>
          <li><a href="#services" onClick={toggleMenu} className="block hover:text-[#2B9E51] font-semibold">الشروط والمستندات</a></li>
          <li><a href="#contact" onClick={toggleMenu} className="block hover:text-[#2B9E51] font-semibold">شركاء التنفيذ</a></li>
          <li>
            <Link to="/replacement">
              <button onClick={toggleMenu} className="px-4 py-2 bg-[#1B6333] text-white hover:bg-[#2B9E51] rounded-md">
                الإشتراك بالمبادرة
              </button>
            </Link>
          </li>
        </ul>
      </nav>

      <div className="flex justify-between items-center">
        {/* Logos */}
        <div className="flex items-center space-x-4">
          <a href="https://www.petroleum.gov.eg/ar-eg" target='_blank' rel='noreferrer'>
            <img
              src={logo1}
              alt="Logo 1"
              className={`transition-all duration-700 h-[75px]`} // Changes height on scroll
            />
          </a>
          <a href="https://www.egas.com.eg/ar" target='_blank' rel='noreferrer'>
            <img
              src={logo2}
              alt="Logo 2"
              className={`transition-all duration-700 h-[75px]`} // Changes height on scroll
            />
          </a>
          {/* <a href="https://mof.gov.eg/ar" target='_blank' rel='noreferrer'>
            <img
              src={logo3}
              alt="Logo 2"
              className={`transition-all duration-700 h-[75px]`} // Changes height on scroll
            />
          </a> */}
        </div>

        {/* Hamburger Icon for Mobile */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>

        {/* Navigation - visible on medium screens and up */}
        <nav className="hidden md:flex space-x-6 space-x-reverse justify-between items-center">
          <a href="/" className="hover:text-[#2B9E51] font-semibold">الرئيسية</a>
          <a href="#about" className="hover:text-[#2B9E51] font-semibold">عن المبادرة</a>
          <a href="#services" className="hover:text-[#2B9E51] font-semibold">الشروط والمستندات</a>
          <a href="/partners" className="hover:text-[#2B9E51] font-semibold">شركاء التنفيذ</a>
          <Link to="/replacement">
            <button className="px-4 py-2 bg-[#1B6333] text-white hover:bg-[#2B9E51] rounded-md">
              الإشتراك بالمبادرة
            </button>
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
