import React, { useState, useEffect } from "react";
import po from '../../assets/banner1.jpg';
import cng from '../../assets/cng.png';
import station from '../../assets/station.jpg';
import cost from '../../assets/save.png';

const features = [
  {
    title: "نظيف",
    description:
      `• يحافظ على البيئة .
       • خالٍ من مركبات الرصاص والشوائب الكبريتية.
       • يعمل على خفض الانبعاثات الناتجة عن الاحتراق.`,
    image: po,
  },
  {
    title: "آمــن",
    description:
      `• الغـاز الطبيعـي غيــر سـام.
       • احتمالية اشتعاله ضئيلة مقارنة بأنواع الوقود السائل.
       • منظومة الأمان مجهزة بنظام لغلق الغاز.
       • اسطوانة الغاز الطبيعي مصممة لتحمل الضغوط العالية والصـدمات.`,
    image: cng,
  },
  {
    title: "متوافر",
    description:
      `• تتوافر خدمة التموين بالغاز بكافة محطات التموين بالغاز الطبيعـي بمختلـف محافظات الجمهوريـة.
       • يحقق الغاز الطبيعي لمصـر تأمينـاً لمصـادر الطاقــة.`,
    image: station,
  },
  {
    title: "اقتصادي",
    description:
      `• توفير مبالغ ضخمة من مخصصات الدعم الذي توجهه الدولة للمواد البترولية السائلة. 
       • تحقيق عائد اقتصــادي لمالكــي السيــارات عند تحويـل سياراتهـم للعمل بالغـاز الطبيعي.`,
    image: cost,
  },
];

const HomeFeatures = () => {
  const [background, setBackground] = useState(features[0].image); // Start with the first section's image
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [screenSize, setScreenSize] = useState(window.innerWidth); // Track screen size
  const formatDescription = (description) => {
    return description.replace(/\n/g, "<br />");
  };

  // Update screen size on resize
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // UseEffect to get image dimensions
  useEffect(() => {
    const img = new Image();
    img.src = features[0].image;
    img.onload = () => {
      setDimensions({ width: img.width, height: img.height });
    };
  }, []);

  const handleMouseLeave = () => {
    // Don't reset the background, just keep the last hovered one
  };

  return (
    <div
      className="features-box bg-cover bg-center"
      style={{
        backgroundImage: `url(${background})`, // Use the current background state
      }}
    >
      <div className="mx-auto">
        <div className="flex flex-wrap sm:flex-col md:flex-row md:space-y-0">
          {features.map((service, index) => (
            <div
              key={index}
              className="w-full sm:w-full md:w-1/4 px-4 relative group"
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: screenSize <= 768 ? "30vh" : `60vh`, // Dynamically calculate height based on image aspect ratio
                borderTop: index !== 0 && screenSize <= 768 ? "1px solid #fff" : "none", // Small screens
                borderRight: index !== 0 && screenSize > 768 ? "1px solid #fff" : "none", // Medium+ screens
                transition: "background-image 0.3s ease-in-out", // Smooth background change transition
              }}
              onMouseEnter={() => setBackground(service.image)} // Change background on hover
              onMouseLeave={handleMouseLeave} // Keep the last image when mouse leaves
            >
              <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-75 transition-opacity duration-300"></div>
              <div className="absolute inset-0 flex flex-col justify-center items-center text-center lg:text-start text-white p-4">
                <h3 className="text-2xl font-bold mb-4 select-none">{service.title}</h3> {/* Larger title */}
                <p className="text-[12px] lg:text-[18px] mb-4 select-none" dangerouslySetInnerHTML={{
                  __html: formatDescription(service.description),
                }}></p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeFeatures;
